import React from 'react';
import {TermsPageContent} from './TermsPageContent';

const TermsLazyHelper =(props)=>  {
    return (
        <React.Fragment>
            <TermsPageContent />
        </React.Fragment>
    );
};

export default TermsLazyHelper;
